export default [
    {
        path: "/",
        name: "",
        component: () => import("@/pages/products.vue")
    },
    {
        path: "/abouts",
        name: "abouts",
        component: () => import("@/pages/index.vue")
    },
    {
        path: "/videos",
        name: "videos",
        component: () => import("@/pages/videos.vue")
    },

    {
        path: "/downloads",
        name: "downloads",
        component: () => import("@/pages/downloads.vue")
    },
    {
        path: "/questions",
        name: "questions",
        component: () => import("@/pages/questions.vue")
    },
    {
        path: "/mobile-question-detail",
        name: "mobile-question-detail",
        component: () => import("@/pages/mobile-question-detail.vue")
    },
    {
        path: "/mobile-video",
        name: "mobile-video",
        component: () => import("@/pages/mobile-video.vue")
    }
];
