import axios from "axios";
import HTTP_STATE from "./http_state";

class Api {
	constructor(options) {
		const {
			baseUrl,
			headers = {},
			toast,
			logError,
			noTokenHandler,
			tokenHandler
		} = options;

		this.config = {
			baseURL: baseUrl,
			headers: Object.assign(
				{
					"Content-Type": "application/json"
				},
				headers
			)
		};

		// 请求队列
		this.queue = {};

		// 弹窗
		this.toast =
			typeof toast === "function" ? toast: function(data, time = 1500) {console.log(data, time); };

		// 错误日志上报
		this.logError =
			typeof logError === "function" ? logError : function() {};

		// 错误日志上报
		this.noTokenHandler =
			typeof noTokenHandler === "function" ? noTokenHandler : function() {};

		// token处理器
		this.tokenHandler =
			typeof tokenHandler === "function" ? tokenHandler : function() {};
	}

	// 请求队列 -- 销毁
	destroy(url) {
		delete this.queue[url];

		if (!Object.keys(this.queue).length) {
			//
		}
	}

	// 拦截器
	interceptors(instance, url) {
		// 请求拦截
		instance.interceptors.request.use(
			config => {
				// 在发送请求之前做些什么

				this.queue[url] = true;

				return config;
			},
			error => {
				// 对请求错误做些什么

				return Promise.reject(error);
			}
		);

		// 响应拦截
		instance.interceptors.response.use(
			res => {
				// 对响应数据做些什么

				//todo销毁链接
				// this.destroy(url);

				const { data } = res;

				this.toast(res.data);
				return data;
			},
			error => {
				// 对响应错误做些什么

				console.error({ error });

				this.responseErrorHandler(error);
				return Promise.reject(error);
			}
		);
	}

	// 错误处理器
	responseErrorHandler(res) {
		const {
			AUTHENTICATE,
			NOT_FOUND,
			UNPROCESSABLE_ENTITY,
			BAD_GATEWAY,
			SERVER_ERROR,
			FORBIDDEN,
			CLIENT_ERROR
		} = HTTP_STATE;
		const { data, status } = res.response;

		switch (status) {
			//400
			case CLIENT_ERROR:
				this.toast(data);
				break;

			//401
			case AUTHENTICATE:
				this.noTokenHandler(data);
				break;

			//403
			case FORBIDDEN:
				this.toast(data);
				break;

			//404
			case NOT_FOUND:
				this.toast(data);
				break;

			//422
			case UNPROCESSABLE_ENTITY:
				this.toast(data);
				break;

			//500
			case SERVER_ERROR:
				this.toast(data);
				break;

			//502
			case BAD_GATEWAY:
				this.toast(data);
				break;
		}

		this.logError(data, status);
	}

	// 无效参数 undefined | null -- 剔除
	invalidHandler(data = {}) {
		let _data = {};

		Object.keys(data).forEach(key => {
			if (data[key] !== undefined && data[key] !== null) {
				_data[key] = data[key];
			}
		});

		return _data;
	}

	// 请求头部处理器
	headersHandler(headers) {
		return Object.assign(this.config.headers, headers);
	}

	// 请求参数处理器
	optionsHandler(options) {
		let { headers, data, token } = options;
		let _key = data ? "data" : "params";

		options.headers = this.headersHandler(headers, token);
		options[_key] = this.invalidHandler(options[_key]);

		return options;
	}

	/**
	 * @param {Boolean} token 是否携带token
	 */
	request(options) {
		const instance = axios.create();

		console.log({ options });

		options = Object.assign(this.config, this.optionsHandler(options));
		this.interceptors(instance, options.url);

		return instance(options);
	}
}
export default Api;
