import navsItemsIdxs from "@/config/navs-items-idxs";

export const getScrollTop = () => {
    // 兼容不同的浏览器

    return (
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop
    );
};

export const onScrollTo = url => {
    const step = 50;

    let idx = navsItemsIdxs[url];
    let scrollTop = getScrollTop();

    let targetOffsetTop =
        document.querySelector(`.container-item:nth-child(${idx + 1})`)
            .offsetTop - 100;

    if (scrollTop > targetOffsetTop) {
        // 往上滑
        scrollUp();
    } else {
        // 往下滑
        scrollDown();
    }

    function scrollUp() {
        if (scrollTop > targetOffsetTop) {
            if (scrollTop - targetOffsetTop >= step) {
                scrollTop -= step;
            } else {
                scrollTop = targetOffsetTop;
            }

            document.body.scrollTop = scrollTop;
            document.documentElement.scrollTop = scrollTop;

            requestAnimationFrame(scrollUp);
        }
    }

    function scrollDown() {
        if (scrollTop < targetOffsetTop) {
            if (targetOffsetTop - scrollTop >= step) {
                scrollTop += step;
            } else {
                scrollTop = targetOffsetTop;
            }
            document.body.scrollTop = scrollTop;
            document.documentElement.scrollTop = scrollTop;

            requestAnimationFrame(scrollDown);
        }
    }
};

export const onScrollToTop = () => {
    let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;

    let timer = setInterval(() => {
        scrollTop = scrollTop - 50 < 0 ? 0 : scrollTop - 50;

        document.body.scrollTop = scrollTop;
        document.documentElement.scrollTop = scrollTop;

        if (scrollTop <= 0) {
            clearInterval(timer);
        }
    }, 17);
};

export const getUserAgent = () => {
    const ua = window.navigator.userAgent;

    return {
        isIos: /(iPhone|iPad|iPod|iOS)/i.test(ua),
        isAndroid: /(Android)/i.test(ua),
        isWechat: /(MicroMessenger)/i.test(ua),
        isMobile: /(mobile)/i.test(ua),
        isUC: ua.indexOf("UCBrowser") > -1,
        isIPhone: /(iPhone)/i.test(ua)
    };
};
