import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import router from "./router";
import BaiduMap from "vue-baidu-map";
import api from "./api";
import { getUserAgent } from "@/utils";
import "picturefill/dist/picturefill.min.js";
import VideoPlayer from "vue-video-player";
import { Carousel, CarouselItem } from "element-ui";

// import styles
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
import "element-ui/packages/theme-chalk/src/carousel.scss";
import "@/styles/index.less";

Vue.use(BootstrapVue);
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: "36CTc06WoZV9Q5FDrp6m8Cqsw1PqNm3V"
});
Vue.use(VideoPlayer);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$userAgent = getUserAgent();

new Vue({
    render: h => h(App),
    router
}).$mount("#app");
