import Api from "./api.class";

const env = process.env.VUE_APP_SERVER_ENV === "debug" ? "watch_test" : "watch";
const baseUrl = `https://ddl.qimengkeji.com.cn/${env}/`;

const api = new Api({
    baseUrl
});

export default api;
