/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
import routes from "./routers";
import { onScrollTo, onScrollToTop } from "@/utils";

Vue.use(Router);
const router = new Router({
    // mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    next();
});

router.afterEach(to => {
    const { itemUrl } = to.query;

    if (itemUrl) {
        setTimeout(() => {
            try {
                onScrollTo(itemUrl);
            } catch (error) {}
        }, 300);
    } else {
        setTimeout(() => {
            try {
                onScrollToTop();
            } catch (error) {}
        }, 300);
    }
});

export default router;
