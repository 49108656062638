<template>
    <div id="app">
        <VueOrient />

        <router-view></router-view>
    </div>
</template>

<script>
import VueOrient from "vue-orient";

export default {
    name: "App",

    components: {
        VueOrient
    },

    data() {
        return {};
    },

    mounted() {
        window.onload = function() {
            // 阻止双击放大
            var lastTouchEnd = 0;
            document.addEventListener("touchstart", function(event) {
                if (event.touches.length > 1) {
                    event.preventDefault();
                }
            });
            document.addEventListener(
                "touchend",
                function(event) {
                    var now = new Date().getTime();
                    if (now - lastTouchEnd <= 300) {
                        event.preventDefault();
                    }
                    lastTouchEnd = now;
                },
                false
            );

            // 阻止双指放大
            document.addEventListener("gesturestart", function(event) {
                event.preventDefault();
            });
        };
    }
};
</script>
